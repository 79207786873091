/*********    apply tablet media queries only if device is tablet    *************/

const userAgent = navigator.userAgent.toLowerCase();
// device detection
const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
    );

var isMobile = false; //initiate as false
// device detection
if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
    )
) {
    isMobile = true;
}

var desktopQuery = `

@media screen and (max-width: 1300px) {

    .vimeo-wrapper {
        min-height: 600px;
    }

    .sidebar-eye,
    .sidebar-eye-hover {
        padding-right: 22%;
    }

    .inner-section-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 6vh 0;
        max-width: 80%;
    }

    .heading-text {
        max-width: 75%;
    }

    .header-container .row {
        width: 115%;
    }

    #flow-hero-logo {
        width: 100%;
    }

    .first-inter-second-col .hell-greenwashing-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .sidebar-menu-item {
        margin: 15px 0;
    }

    .sidebar-menu-item-arrow {
        display: none;
    }

    #second-section {
        display: none;
    }

    .hero-cop26 {
        font-size: 2.4em;
    }

    .welcome-text-wrapper .col {
        font-size: 0.9em;
    }

    .welcome-video-container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        padding-top: 10vh;
    }

    .section-heading {
        font-size: 3.5em;
        text-shadow: 3px 3px 4px #000;
        color: #fff;
    }

    .heading-text {
        font-size: 1.4em;
    }

    /*  making waves */
    .waves-arrow-col {
        display: none;
    }

    .making-waves-container {
        width: 100%;
    }

    .making-waves-text-wrapper {
        width: 98%;
        margin: 0 auto;
    }

    #christina-wong-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #christina-wong-container .making-waves-text-wrapper {
        margin: 0 1em;
        width: 90%;
    }

    #sculpture-1 {
        left: 5%;
        top: -10%;
        max-width: 16%;
    }

    #sculpture-2 {
        left: -4%;
    }

    #sculpture-3 {
        bottom: 6%;
    }

    #sculpture-4 {
        right: -3%;
        bottom: 6%;
    }

    #james-pfaff-2 {
        right: 0;
    }

    #james-pfaff-3 {
        right: -8%;
    }

    #potr-3 {
        right: 2%;
        bottom: 2%;
    }

    #karen-1 {
        display: none;
    }

    .sustainable-container {
        height: auto;
    }

    .figure {
        position: static;
        width: 100%;
    }

    /*  sustainable */

    .sustainable-container {
        height: 100%;
        margin: 5vh 0;
    }

    .sustainable-container-1 {
        height: auto;
    }

    .figure.sustainable-figure-1 {
        position: static;
    }

    .figure.sustainable-figure-2 {
        position: static;
    }

    .sustainable-heading-text {
        z-index: 2;
        position: static;
    }

    .sustainable-heading {
        top: 10%;
        left: 5%;
    }


    .sustainable-heading-text {
        position: static;
        width: 100%;
        font-size: 1.4em;
    }

    .sustainable-line-1 {
        position: absolute;
        bottom: 58%;
        z-index: -1;
    }

    .sustainable-container-3 .container-text-right-col {
        margin-top: 0;
    }

    .sustainable-container-2 {
        margin-top: 0;
    }

    .sustainable-container-3 {
        margin-top: 0;
    }

    .sustainable-container-4 {
        padding-top: 0;
    }

    .sustainable-container-5 {
        margin-top: 0;
    }

    .sustainable-container-6 {
        margin-top: 0;
    }

    .figure-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .figure {
        max-width: 650px;
        padding: 1em;
        margin: 0 auto;
    }

    .sustainable-container-3 .container-text-left-col {
        margin-top: 0;
    }


    .sustainable-line-2 {
        bottom: -2%;
    }

    /*   after november  */

    .after-november-container {
        height: 100%;
        margin: 5vh 0;
    }

    .after-november-container-1 {
        height: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .after-november-container-1 .figure-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .after-november-heading {
        top: 12%;
        left: 10%;
    }

    .after-november-heading-text {
        position: static;
    }

    .after-november-figure-1 {
        position: static;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .after-november-figure-2 {
        position: static;
    }

    .after-november-line-1 {
        position: absolute;
        bottom: 58%;
        z-index: -1;
    }


    .after-november-line-3 {
        bottom: -5%;
    }

    .after-november-container-4 {
        margin-top: -10vh;
    }

    .figure img {
        max-width: 100%;
    }

    /* climate literacy */

    .climate-literacy-container {
        height: 100%;
    }

    .climate-literacy-container-1 {
        height: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .climate-literacy-heading {
        top: 10%;
        left: 5%;
    }

    .climate-literacy-figure-1 {
        position: static;
    }

    .climate-literacy-figure-2 {
        position: static;
    }

    .climate-literacy-heading-text {
        position: static;
        left: 5%;
        margin-bottom: 5vh;
    }

    .climate-literacy-line-1 {
        position: absolute;
        bottom: 58%;
        z-index: -1;
    }

    .climate-literacy-container-3 {
        margin-top: 0;
    }

    .climate-container-left-col-4,
    .climate-container-right-col-4 {
        margin-top: 0;
    }

    .climate-literacy-container-6 {
        margin-top: 0;
    }

    .fourth-inner-section {
        margin-top: 0;
    }

    .fourth-inner-container {
        width: 90%;
    }

    .fourth-inner-text-wrapper {
        width: 100%;
    }

    /*  in process   */

    .close-of-main-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        width: 85%;
    }

    .close-arrow-col {
        display: none;
    }
 
}


@media screen and (max-width: 850px) {

    .container-text-wrapper {
        flex-direction: column;
    }

    .container-text-wrapper .col {
        width: 100%;
    }

    .sidebar-menu {
        width: 100%;
    }

    .sidebar-carousel-container {
        width: 0%;
    }

    .sidebar-carousel-slide {
        right: 100%;
    }
}

@media screen and (min-width: 850px) {

    #welcome-div:hover, #climat-liter-div:hover {
        background-color: #000;
        color: #fff;
    }

    #cornerstone-div:hover, #close-of-div:hover {
        background-color: #29FF42;
    }

    #making-waves-div:hover, #after-november-div:hover {
        background-color: #E2E2E2;
    }
}`;
// Your CSS as text
var tabletQuery = `

@media screen and (max-width: 1280px) {
    .sidebar-menu {
        width: 30%;
    }

    .container-text-wrapper p {}

    .inner-section-wrapper {
        display: flex;
        flex-direction: column;
        padding: 6vh 0;
        max-width: 80%;
    }

    .sixth-inner-section-wrapper .first-inter-first-col {
        padding-top: 0;
    }

    .second-inner-section-wrapper {
        padding-right: 0;
    }

    .tom-morton-2 {
        top: 50%;
    }

    .seventh-inner-section-wrapper .first-inter-first-col {
        padding-top: 5vh;
    }

    .fourth-inner-section {
        margin-top: -40vh;
    }

    .fourth-inner-container {
        width: 80%;
    }

    .fourth-inner-text-wrapper {
        width: 80%;
    }

    .sidebar-social-row {
        padding: 1.5rem 0.2rem 1.5rem 0;
    }

    .sidebar-carousel-container {
        width: 70%;
    }

    .sidebar-carousel-slide {
        right: 30%;
    }

    .sidebar-menu .sidebar-menu-row {
        margin-left: 1vw;
    }

    .sidebar-menu-item {
        width: 100% !important;
        margin: 30px 0;
    }

    .mySlides img {
        -o-object-position: 40% 90%;
        object-position: 40% 90%;
    }

    #welcome-div:hover,
    #climat-liter-div:hover {
        background-color: transparent;
        color: #000;
    }

    #cornerstone-div:hover,
    #close-of-div:hover {
        background-color: transparent;
    }

    #making-waves-div:hover,
    #after-november-div:hover {
        background-color: transparent;
    }

    .main-menu-row {
        height: 90vh;
    }

    .flow-wrapper {
        width: 75vw;
    }

    .flow-wrapper-slide {
        display: none;
    }

    .welcome-text-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 4vh 0;
    }

    .container-text-wrapper {
        width: 100%;
    }

    .welcome-text-wrapper .col {
        font-size: 1.4em;
    }

    .welcome-video-container {
        padding: 0;
    }

    .welcome-video-container {
        width: 80%;
    }

    .main-menu-item {
        padding-left: 0;
        text-align: center;
    }

    .welcome-img {
        width: 70%;
    }

    .making-waves-container {
        width: 80%;
    }

    .vimeo-wrapper {
        min-height: 400px;
    }

    #christina-wong-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #christina-wong-container .making-waves-text-wrapper {
        margin-left: 0;
    }

    .welcome-credits-container {
        padding: 10vh 0 20vh 0;
    }

    #making-waves-section {
        padding-top: 8vh;
    }

    .making-waves-heading {
        padding-bottom: 1em;
    }

    // #sculpture-placement-iframe {
    //     height: 490px;
    // }

    .making-waves-text-wrapper {
        width: 90%;
    }

    #sculpture-1 {
        left: 0;
        top: -12%;
        max-width: 12%;
    }

    #sculpture-2 {
        left: -10%;
        top: -12%;
        max-width: 35%;
    }

    #sculpture-3 {
        right: 0;
        bottom: 0;
        max-width: 16%;
    }

    #sculpture-4 {
        right: 0;
        bottom: 0;
        max-width: 35%;
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    #glasgow-tool-1 {
        top: 90%;
        left: 75%;
        max-width: 10%;
    }

    #glasgow-tool-2 {
        top: 85%;
        left: 75%;
        max-width: 30%;
    }

    #karen-1 {
        left: 0;
        bottom: 0;
        max-width: 100%;
    }

    #karen-2 {
        right: 5%;
        bottom: 0;
        max-width: 20%;
    }

    #potr-1 {
        left: -20%;
        bottom: 45%;
        max-width: 20%;
    }

    #potr-2 {
        left: -30%;
        bottom: 40%;
        max-width: 40%;
    }

    #potr-3 {
        right: -6%;
        bottom: 5%;
        max-width: 16%;
    }

    #james-pfaff-1 {
        left: -40%;
        bottom: 50%;
        max-width: 16%;
    }

    #james-pfaff-2 {
        right: -10%;
        bottom: 0;
        max-width: 16%;
    }

    #james-pfaff-3 {
        right: -15%;
        bottom: 0;
        max-width: 40%;
    }

    #allot-me-1 {
        left: -18%;
        bottom: 30%;
        max-width: 20%;
    }

    #zero-waste-1 {
        left: -15%;
        bottom: 36%;
        max-width: 20%;
    }

    #zero-waste-2 {
        right: -10%;
        bottom: 2%;
        max-width: 25%;
    }

    #circular-arts-1 {
        -webkit-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        transform: rotate(-200deg);
        position: absolute;
        left: -30%;
        bottom: 12%;
        max-width: 30%;
    }

    /*     sustainable section   */
    // .sustainable-section {
    //     margin: 10vh 0 50vh 0;
    // }


    .sustainable-container-1 .row {
        grid-template-columns: 1fr;
    }

     .sustainable-1-left-col {
        padding-top: 0%;
    }

    .climate-literacy-container-1 .sustainable-1-left-col {
        padding-top: 80%;
    }

    // .sustainable-container {
    //     height: 80vh;
    // }

    // .sustainable-container-1 {
    //     height: 70vh;
    // }

    .sustainable-figure-1 {
        width: 50%;
    }

    .sustainable-figure-2 {
        width: 50%;
        right: 0;
    }

    .section-heading {
        font-size: 40px;
    }

    .sustainable-heading-text {
        top: 40%;
        left: 35%;
        max-width: 80%;
    }

    .sustainable-figure-8 {
        bottom: -24%;
    }

    .sustainable-figure-9 {
        bottom: -30%;
    }

    .sustainable-container-4 {
        padding-top: 18vh;
    }

    .sustainable-figure-10 {
        bottom: 30%;
    }

    .sustainable-container-5 {
        margin-top: 0;
    }

    .sustainable-figure-11 {
        top: -20%;
        left: 10%;
    }

    .sustainable-container-6 {
        margin-top: -70vh;
    }

    .sustainable-figure-12 {
        top: -43%;
    }

    .sustainable-figure-14 {
        top: 26%;
    }

    .sustainable-figure-15 {
        bottom: -20%;
    }

    .sustainable-figure-16 {
        bottom: 0;
    }

    .sustainable-figure-17 {
        bottom: -60%;
        left: 30%;
    }

    .sustainable-line-2 {
        bottom: -20%;
    }

    /*  after november   */
    // .after-november-section {
    //     margin: 0 0 50vh 0;
    // }

    // .after-november-container {
    //     height: 80vh;
    // }

    .after-november-container-1 .row {
        grid-template-columns: 1fr;
    }

    .after-november-1-right-col {
        display: none;
    }

    .after-november-figure-1 {
        top: 8%;
    }

    .after-november-figure-2 {
        top: 50%;
        right: 8%;
        max-width: 35%;
    }

    .after-november-figure-4 {
        top: 20%;
    }

    .after-november-figure-6 {
        bottom: -6%;
    }

    .after-november-container-4 {
        margin-top: 8vh;
    }

    .after-november-figure-7 {
        bottom: -32%;
    }

    .after-november-line-3 {
        bottom: -18%;
    }

    /*  climate literacy  */
    .climate-literacy-section {
        margin-top: 0;
    }

    // .climate-literacy-container {
    //     height: 80vh;
    // }

    .climate-literacy-heading-text {
        top: 46%;
    }

    .climate-literacy-container-1 {
        height: 70vh;
    }

    .climate-literacy-figure-5 {
        bottom: -56%;
    }

    .climate-literacy-figure-6 {
        bottom: -65%;
    }

    .climate-literacy-container-3 {
        margin-top: 54vh;
    }

    .climate-literacy-container-4 {
        margin-top: 15vh;
    }

    .climate-container-left-col-4 {
        margin-top: 25vh;
    }

    .climate-container-right-col-4 {
        margin-top: 22vh;
    }

    .climate-literacy-figure-9 {
        top: -16%;
    }

    .climate-literacy-figure-10 {
        bottom: 73%;
    }

    .climate-literacy-figure-11 {
        bottom: 78%;
    }

    .climate-literacy-container-6 {
        margin-top: -88vh;
    }

    #close-of-play-section {
        margin-top: 0;
    }

    .close-of-header-anim {
        padding-top: 10vh;
        height: 30vh;
    }

    .close-of-play-anim-1 {
        left: 23%;
        top: 36%;
    }

    .close-of-play-anim-2 {
        right: 23%;
        top: 20%;
    }

    .close-of-main-wrapper {
        max-width: 90%;
    }

    .close-of-main-wrapper {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
    
@media screen and (max-width: 850px) {

    /* sustainable */

    // .sustainable-section {
    //     margin: 10vh 0 40vh 0;
    // }

    // .sustainable-container {
    //     height: 105vh;
    // }

    .sustainable-figure-1 {
        top: 36%;
    }

    .sustainable-figure-2 {
        width: 45%;
    }

    .sustainable-container-3 {
        margin-top: 12vh;
    }

    .sustainable-figure-3,
    .sustainable-figure-4 {
        bottom: -16%;
    }

    .sustainable-figure-5 {
        bottom: -8%;
    }

    .sustainable-figure-6 {
        bottom: 65%;
    }

    .sustainable-figure-9 {
        bottom: -20%;
        right: 10%;
    }

    .sustainable-container-3 .container-text-left-col {
        margin-top: 35vh;
    }

    .sustainable-container-3 .container-text-right-col {
        margin-top: -5vh;
    }

    .sustainable-figure-10 {
        bottom: 45%;
    }

    .sustainable-figure-11 {
        top: 7%;
    }

    .sustainable-figure-12 {
        top: -60%;
    }

    .sustainable-figure-13 {
        bottom: 10%;
    }

    .sustainable-container-5 {
        margin-top: -20vh;
    }

    .sustainable-figure-14 {
        top: 14%;
    }

    .sustainable-figure-15 {
        bottom: -10%;
        left: 60%;
    }

    .sustainable-figure-16 {
        bottom: 20%;
    }

    .sustainable-figure-17 {
        bottom: -20%;
        left: 4%;
    }

    /*  after november  */
    // #after-november-section {
    //     margin-top: 0;
    //     margin: 0 0 90vh 0;
    // }

    // .after-november-container {
    //     height: 113vh;
    // }

    .after-november-heading {
        top: 40%;
    }

    .after-november-heading-text {
        top: 48%;
    }

    .after-november-figure-2 {
        right: 4%;
        top: 40%;
    }

    .after-november-figure-4 {
        top: 46%;
    }

    .after-november-figure-5 {
        left: 4%;
        bottom: -22%;
    }

    .after-november-figure-6 {
        bottom: -14%;
    }

    .after-november-container-4 {
        margin-top: 15vh;
    }

    .after-november-figure-7 {
        bottom: -28%;
    }

    /* climate literacy */
    .climate-literacy-section {
        margin-top: 0;
    }

    .climate-literacy-heading-text {
        top: 40%;
    }

    // .climate-literacy-container {
    //     height: 100vh;
    // }

    // .climate-literacy-container-2 {
    //     height: 115vh;
    // }

    .climate-literacy-figure-4 {
        bottom: -14%;
    }

    .climate-literacy-figure-5 {
        bottom: -45%;
    }

    .climate-literacy-figure-6 {
        bottom: -50%;
        right: 16%;
    }

    .climate-literacy-container-3 {
        height: 120vh;
    }

    .climate-literacy-figure-9 {
        top: -10%;
    }

    .climate-literacy-figure-10 {
        bottom: 80%;
    }

    .climate-literacy-figure-11 {
        bottom: 45%;
    }

    .climate-literacy-line-2 {
        top: 10%;
    }
}

@media (min-width: 600px) and (max-width: 1025px) {
    .welcome-credits-container {
        font-size: 1.4em;
    }

    .main-menu-item-text {
        font-size: 40px !important;
    }
}


@media screen and (max-width: 600px) {

    .sidebar-menu-item {
        font-size: 22px;
    }

    .sidebar-menu-row {
        max-width: 100%;
    }

   .vimeo-wrapper {
        min-height: 275px;
    }

    .figure-wrapper-col {
        padding: 0 !important;
    }

    .header-container .row {
        width: 115%;
    }

    #flow-hero-logo {
        width: 100%;
    }

    .first-inter-second-col .hell-greenwashing-row {
        display: flex;
    }

    .sidebar-menu {
        width: 100%;
    }

    .sidebar-menu-item {
        margin: 15px 0;
    }

    .sidebar-menu-item-arrow {
        display: none;
    }

    .sidebar-carousel-container {
        width: 0%;
    }

    .sidebar-carousel-slide {
        right: 100%;
    }

    #second-section {
        display: none;
    }

    .hero-cop26 {
        font-size: 2.4em;
    }

    .welcome-text-wrapper .col {
        font-size: 0.9em;
    }

    .welcome-video-container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
    }

    iframe {
        height: 30vh !important;
    }


    .section-heading {
        font-size: 2.75em;
        text-shadow: 3px 3px 4px #000;
        color: #fff;
    }

    .heading-text {
        font-size: 1.4em;
    }

    .climate-literacy-container-1 .sustainable-1-left-col {
        padding-top: 20%;
    }

    /*  making waves */
    .waves-arrow-col {
        display: none;
    }

    .making-waves-container {
        width: 100%;
    }

    .making-waves-text-wrapper {
        width: 100%;
    }

    #christina-wong-container .making-waves-text-wrapper {
        margin: 0 1em;
        width: 90%;
    }

    #sculpture-1 {
        left: 5%;
        top: -10%;
        max-width: 16%;
    }

    #sculpture-2 {
        left: -4%;
    }

    #sculpture-3 {
        bottom: 6%;
    }

    #sculpture-4 {
        right: -3%;
        bottom: 6%;
    }

    #james-pfaff-2 {
        right: 0;
    }

    #james-pfaff-3 {
        right: -8%;
    }

    #potr-3 {
        right: 2%;
        bottom: 2%;
    }

    #karen-1 {
        display: none;
    }

    .sustainable-container {
        height: auto;
    }

    .container-text-wrapper {
        flex-direction: column;
    }

    .figure {
        position: static;
        width: 100%;
    }

    /*  sustainable */

    .sustainable-container {
        height: 100%;
        margin: 5vh 0;
    }

    .sustainable-container-1 {
        height: auto;
    }

    .figure.sustainable-figure-1 {
        position: static;
    }

    .figure.sustainable-figure-2 {
        position: static;
    }

    .sustainable-heading-text {
        z-index: 2;
        position: static;
    }

    .sustainable-heading {
        top: 10%;
        left: 5%;
    }


    .sustainable-heading-text {
        position: static;
        width: 100%;
        font-size: 1.4em;
    }

    .sustainable-line-1 {
        position: absolute;
        bottom: 58%;
        z-index: -1;
    }

    .sustainable-container-3 .container-text-right-col {
        margin-top: 0;
    }

    .sustainable-container-2 {
        margin-top: 0;
    }

    .sustainable-container-3 {
        margin-top: 0;
    }

    .sustainable-container-4 {
        padding-top: 0;
    }

    .sustainable-container-5 {
        margin-top: 0;
    }

    .sustainable-container-6 {
        margin-top: 0;
    }

    .figure-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .figure {
        max-width: 100%;
        padding: 1em;
    }

    .sustainable-container-3 .container-text-left-col {
        margin-top: 0;
    }


    .sustainable-line-2 {
        bottom: -2%;
    }

    /*   after november  */

    .after-november-container {
        height: 100%;
        // margin: 5vh 0;
    }

    .after-november-container-1 {
        height: auto;
        display: flex;
    }

    .after-november-container-1 .figure-wrapper {
        display: flex;
    }

    .after-november-heading {
        top: 12%;
        left: 10%;
    }

    .after-november-heading-text {
        position: static;
    }

    .after-november-figure-1 {
        position: static;
        order: 1;
    }

    .after-november-figure-2 {
        position: static;
    }

    .after-november-line-1 {
        position: absolute;
        bottom: 58%;
        z-index: -1;
    }


    .after-november-line-3 {
        bottom: -5%;
    }

    .after-november-container-4 {
        margin-top: -10vh;
    }

    .after-november-heading-text {
        max-width: 90%;
    }

    .figure img {
        max-width: 100%;
    }

    /* climate literacy */

    .climate-literacy-container {
        height: 100%;
    }

    .climate-literacy-container-1 {
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .climate-literacy-heading {
        top: 10%;
        left: 5%;
    }

    .climate-literacy-figure-1 {
        position: static;
    }

    .climate-literacy-figure-2 {
        position: static;
    }

    .climate-literacy-heading-text {
        position: static;
        max-width: 95%;
        left: 5%;
        margin-bottom: 5vh;
    }

    .climate-literacy-line-1 {
        position: absolute;
        bottom: 58%;
        z-index: -1;
    }

    .climate-literacy-container-3 {
        margin-top: 0;
    }

    .climate-container-left-col-4,
    .climate-container-right-col-4 {
        margin-top: 0;
    }

    .climate-literacy-container-6 {
        margin-top: 0;
    }

    .fourth-inner-section {
        margin-top: 0;
    }

    .fourth-inner-container {
        width: 90%;
    }

    .fourth-inner-text-wrapper {
        width: 100%;
    }

    /*  in process   */

    .close-of-main-wrapper {
        display: flex;
        flex-direction: column-reverse;
        width: 85%;
    }

    .close-arrow-col {
        display: none;
    }

    .close-of-header-anim {
        padding-top: 0;
    }

    .close-of-play-anim-1,
    .close-of-play-anim-2 {
        width: 30%;
    }

    .close-of-play-anim-1 {
        top: 42%;
    }

    .close-of-play-anim-2 {
        top: 26%;
    }

    .data-heading {
        font-size: 28px;
    }
}
`;

if (isTablet || isMobile) {
    var styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = tabletQuery;
    document.head.appendChild(styleSheet);
} else {
    var styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = desktopQuery;
    document.head.appendChild(styleSheet);
}
